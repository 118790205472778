import { IErrorRequest } from "../data/models"

export default class ErrUtils {
	public static buildErrMessage(response: any): string {
		let msg: string = ""

		if (response && response.data) {
			const data: IErrorRequest = response.data

			if (response.status >= 400 && response.status < 500) {
				msg += `Wrong data request`
			} else if (response.status >= 500 && response.status < 600) {
				msg += `Server error`
			}

			msg += `Code: ${data.code}, error: ${data.message}`

			if (data.details) {
				msg += `, detail: ${data.details}`
			}

			if (data.hint) {
				msg += `, hint: ${data.hint}`
			}

			return msg
		} else {
			msg = `Error is undefined. ${response?.message ?? ""}`
		}

		return msg
	}
}
