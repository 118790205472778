import React, { Component, Suspense } from "react";
import "./App.scss";
import { Provider } from "mobx-react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AppRoutes } from "./app/AppRoutes";
import { Loading } from "./vidgets/loading/Loading";

import AppStore from "./stores/AppStore";
import MyDataUsView from "./views/MyData/MyDataUsView";
//import DashboardStore from "./stores/DashboardStore";

const appStore = new AppStore();
// const dashboardStore = new DashboardStore();

export const stores = {
  appStore,
  // dashboardStore,
};

export interface IProps {}
export interface ISate {}

const HomeView = React.lazy(() => import("./views/Home/HomeView"));
const SignInView = React.lazy(() => import("./views/Home/SignInView"));
//Dashboard
const DashboardView = React.lazy(
  () => import("./views/Dushboard/DashBoardView")
);
const PersonalDetailsView = React.lazy(
  () => import("./views/Dushboard/PersonalDetailsView")
);
const NewLoanView = React.lazy(() => import("./views/Dushboard/NewLoanView"));
const TransActionsView = React.lazy(
  () => import("./views/Dushboard/TransActionsView")
);
const MakePaymentView = React.lazy(
  () => import("./views/Dushboard/MakePaymentView")
);
const MessageView = React.lazy(() => import("./views/Dushboard/MessageView"));
const NotificationsView = React.lazy(
  () => import("./views/Dushboard/NotificationsView")
);
//SignUp
const SignUpNewLoanView = React.lazy(
  () => import("./views/SignUP/SignUpNewLoanView")
);
const SignUpPersonalDetails = React.lazy(
  () => import("./views/SignUP/SignUpPersonalDetails")
);
const LoanParticulars = React.lazy(
  () => import("./views/SignUP/LoanParticularsView")
);
const IdentityVerificationView = React.lazy(
  () => import("./views/SignUP/IdentityVerificationView")
);
const BankStatementsView = React.lazy(
  () => import("./views/SignUP/BankStatementView")
);
const CenterLinkView = React.lazy(
  () => import("./views/SignUP/CenterLinkView")
);
const EmployerView = React.lazy(() => import("./views/SignUP/EmployerView"));
const AdditionalContactView = React.lazy(
  () => import("./views/SignUP/AdditionalContactView")
);
const LoanAgeementView = React.lazy(
  () => import("./views/SignUP/LoanAgreementView")
);
const FinishSignUpView = React.lazy(
  () => import("./views/SignUP/FinishSignUpView")
);
const SignatureView = React.lazy(() => import("./views/SignUP/SignatureView"));
//About
const AboutUsView = React.lazy(() => import("./views/About/AboutUsView"));
//Contact
const ContactView = React.lazy(() => import("./views/Contact/ContactView"));
//Privacy Policy
const PrivacyPolicyView = React.lazy(
  () => import("./views/PrivacyPolicy/PrivacyPolicyView")
);
//Recover password
const ForgotPasswordView = React.lazy(
  () => import("./views/RestorePassword/ForgotPasswordView")
);
const NewPasswordView = React.lazy(
  () => import("./views/RestorePassword/NewPasswordView")
);
//Refresh bank feeds
const RefreshBankFeedsView = React.lazy(
  () => import("./views/RefreshBankFeeds/RefreshBankFeedsView")
);
const RefreshBankFeedsConfirmationView = React.lazy(
  () => import("./views/RefreshBankFeeds/RefreshBankFeedsConfirmationView")
);
//Faq
const FaqView = React.lazy(
  () => import("./views/FAQ/FaqView")
);

export default class App extends Component<IProps, ISate> {
  render() {
    return (
      <BrowserRouter basename={"/ugo"}>
        <Suspense fallback={<Loading />}>
          <Provider {...stores}>
            <Switch>
              <Route exact path={AppRoutes.HOME} component={HomeView} />
              <Route path={AppRoutes.SIGN_IN} component={SignInView} />
              {/* DASHBOARD */}
              <Route path={AppRoutes.DASHBOARD} component={DashboardView} />
              <Route
                path={AppRoutes.PERSONAL_DETAILS}
                component={PersonalDetailsView}
              />
              <Route path={AppRoutes.NEW_LOAN} component={NewLoanView} />
              <Route
                path={AppRoutes.TRANSACTIONS}
                component={TransActionsView}
              />
              <Route
                path={AppRoutes.MAKE_PAYMENT}
                component={MakePaymentView}
              />
              <Route path={AppRoutes.MESSAGE} component={MessageView} />
              <Route
                path={AppRoutes.NOTIFICATIONS}
                component={NotificationsView}
              />
              {/* Sign UP */}
              <Route
                path={AppRoutes.SIGN_UP_NEW_LOAN}
                component={SignUpNewLoanView}
              />
              <Route
                path={AppRoutes.SIGN_UP_PERSONAL_DETAILS}
                component={SignUpPersonalDetails}
              />
              <Route
                path={AppRoutes.SIGN_UP_LOAN_PARTICULARS}
                component={LoanParticulars}
              />
              <Route
                path={AppRoutes.SIGN_UP_IDENTITY}
                component={IdentityVerificationView}
              />
              <Route
                path={AppRoutes.SIGN_UP_BANK_STATEMENTS}
                component={BankStatementsView}
              />
              <Route
                path={AppRoutes.SIGN_UP_CENTERLINK}
                component={CenterLinkView}
              />
              <Route
                path={AppRoutes.SIGN_UP_EMPLOYER}
                component={EmployerView}
              />
              <Route
                path={AppRoutes.SIGN_UP_ADDITIONAL_CONTACT}
                component={AdditionalContactView}
              />
              <Route
                path={AppRoutes.SIGN_UP_AGREEMENT}
                component={LoanAgeementView}
              />
              <Route
                path={AppRoutes.SIGN_UP_SIGNATURE}
                component={SignatureView}
              />
              <Route
                path={AppRoutes.SIGN_UP_FINISH}
                component={FinishSignUpView}
              />
              {/* About GO */}
              <Route path={AppRoutes.ABOUT_UGO} component={AboutUsView} />
              {/* Ugo Data */}
              <Route path={AppRoutes.MY_DATA} component={MyDataUsView} />
              {/* Contact */}
              <Route path={AppRoutes.CONTACT_US} component={ContactView} />
              {/* FAQ */}
              <Route
                path={AppRoutes.FAQ}
                component={FaqView}
              />
              {/* PrivacyPolicyView */}
              <Route
                path={AppRoutes.PRIVACY_POLICY}
                component={PrivacyPolicyView}
              />
              {/* Restore password */}
              <Route
                path={AppRoutes.FORGOT_PASSWORD}
                component={ForgotPasswordView}
              />
              <Route
                path={AppRoutes.NEW_PASSWORD}
                component={NewPasswordView}
              />
              {/* {RefreshBankFeeds} */}
              <Route
                path={AppRoutes.REFRESH_BANK_FEEDS}
                component={RefreshBankFeedsView}
              />
              <Route
                path={AppRoutes.REFRESH_BANK_FEEDS_CONFIRMATION}
                component={RefreshBankFeedsConfirmationView}
              />
            </Switch>
          </Provider>
        </Suspense>
      </BrowserRouter>
    );
  }
}
