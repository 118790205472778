import React, { PureComponent } from "react"

import person from "./../../accets/images/icon/person.svg"
import "./SliderClient.scss"
import Testimonials from "../testimonials/Testimonials"

export interface IProps {}
export interface IState {}

export default class SliderClient extends PureComponent<IProps, IState> {
	constructor(props: IProps) {
		super(props)
		this.state = {}
		//testimonials
		this.findTestimonials = this.findTestimonials.bind(this)
	}
	componentDidMount() {
		this.findTestimonials()
	}

	/**
	 * findTestimonials
	 */
	public findTestimonials(): any {
		const map = new google.maps.Map(document.getElementById("map") as Element, {
			center: { lat: -37.9900433, lng: 145.2126107 },
			zoom: 15.96,
			//https://www.google.com/maps/place/Ugo/@-37.9900433,145.2126107,15.96z/data=!4m12!1m6!3m5!1s0x0:0xc8925a61fe05b33d!2sUgo!8m2!3d-37.9892019!4d145.2139963!3m4!1s0x0:0xc8925a61fe05b33d!8m2!3d-37.9892019!4d145.2139963?hl=sk
		})
		// console.log('process.env.REACT_APP_PLACE_ID_KEY: ', process.env.REACT_APP_PLACE_ID_KEY)
		const request = {
			placeId: `${process.env.REACT_APP_PLACE_ID_KEY}`,
			fields: ["name", "formatted_address", "place_id", "geometry", "reviews"],
		}
		var service = new google.maps.places.PlacesService(map)

		const reviewboxOne = document.getElementById("googleMap_one")!
		const reviewboxTwo = document.getElementById("googleMap_two")!
		const screenWidth = window.screen.width
		service.getDetails(request, function (place, status) {
			// if (screenWidth >= 420) {
			place?.reviews?.forEach((el, idx) => {
				if (idx < 3) {
					if (screenWidth < 420) {
						if (idx < 1) {
							reviewboxOne.innerHTML += `<div class='testimonials-block'>
							<Box class='tm-photo'>
								<img
									alt='tm-img'
									class='tm-img'
									src=${el.profile_photo_url} 
									 
								/>
							</Box>
							<Typography class='tm-name'>${el.author_name}</Typography>
							<Typography class='tm-review'>${el.text}</Typography>
							</div>`
						}
					} else {
						reviewboxOne.innerHTML += `<div class='testimonials-block'>
							<Box class='tm-photo'>
								<img alt='tm-img' class='tm-img' src=${el.profile_photo_url}  />
							</Box>
							<Typography class='tm-name'>${el.author_name}</Typography>
							<Typography class='tm-review'>${el.text}</Typography>
						</div>`
					}
				} else {
					if (screenWidth < 420) {
						if (idx === 4) {
							reviewboxTwo.innerHTML += `<div class='testimonials-block'>
						<Box class='tm-photo'>
							<img alt='tm-img' class='tm-img' src=${el.profile_photo_url}  />
						</Box>
						<Typography class='tm-name'>${el.author_name}</Typography>
						<Typography class='tm-review'>${el.text}</Typography>
					</div>`
						}
					} else {
						reviewboxTwo.innerHTML += `<div class='testimonials-block'>
							<Box class='tm-photo'>
								<img alt='tm-img' class='tm-img' src=${el.profile_photo_url}  />
							</Box>
							<Typography class='tm-name'>${el.author_name}</Typography>
							<Typography class='tm-review'>${el.text}</Typography>
						</div>`
					}
				}
			})
			// }
		})

		//  <Testimonials name={el.author_name} review={el.text} photo={el.profile_photo_url} />
	}

	render(): JSX.Element {
		const screenWidth = window.screen.width
		return (
			<div className='slider-client'>
				<p className='slider-client_title'>What thousands of people say</p>
				<input type='radio' id='i1' name='images' defaultChecked={true} />
				<input type='radio' id='i2' name='images' />
				<input type='radio' id='i3' name='images' />
				<input type='radio' id='i4' name='images' />
				<input type='radio' id='i5' name='images' />
				<div className='slider_testimonials' id='one'>
					<div id='googleMap_one' className='list-testimonials'>
						<label className='prev-client' htmlFor='i5'>
							<span className='client_arrow'>&#x2039;</span>
						</label>
						<label className='next-client' htmlFor='i2'>
							<span className='client_arrow'>&#x203a;</span>
						</label>
					</div>
				</div>
				<div className='slider_testimonials' id='two'>
					<div className='list-testimonials' id='googleMap_two'>
						{screenWidth >= 420 && (
							<Testimonials
								name={"Samantha Fanolua"}
								review={
									"With great help from Mukual, I was able to get my Loan real quick. Very professional and clear instructions. Thank you Mukul, Ugo is very lucky to have such a great humble customer service person like you on their team.Your help is very much appreciated :)Thanks Ugo Team"
								}
								photo={
									"https://lh3.googleusercontent.com/a/AATXAJwzQyTIj39VTW0VspoRT226r4tS-Kwzc5p53VB6"
								}
							/>
						)}

						<label className='prev-client' htmlFor='i1'>
							<span className='client_arrow'>&#x2039;</span>
						</label>
						<label className='next-client' htmlFor='i3'>
							<span className='client_arrow'>&#x203a;</span>
						</label>
					</div>
				</div>
				<div className='slider_testimonials' id='three'>
					<div className='list-testimonials'>
						{screenWidth < 420 ? (
							<Testimonials
								name={"bradley james"}
								review={
									"Ugo are excellent, professional and so easy to deal with, they treat you as part of their family ( not just another number)and really go above and beyond too help you. All Ugo staff are friendly, polite, professional and helpful, phones are answered quickly, emails are responded to swiftly and staff are really helpful. Loans are easy to apply for either via their website or over the phone, outcomes are delivered quickly and funds transferred within minutes of approval. I highly recommend contacting Ugo whenever you need finance. 10/10 , very happy return customer. Thanks Ugo for helping me when I needed it most."
								}
								photo={
									"https://lh3.googleusercontent.com/a/AATXAJyHoFYpecqxND6WwCEHOTQZB5OtNHP9QnZZp4fk"
								}
							/>
						) : (
							<>
								<Testimonials
									name={"bradley james"}
									review={
										"Ugo are excellent, professional and so easy to deal with, they treat you as part of their family ( not just another number)and really go above and beyond too help you. All Ugo staff are friendly, polite, professional and helpful, phones are answered quickly, emails are responded to swiftly and staff are really helpful. Loans are easy to apply for either via their website or over the phone, outcomes are delivered quickly and funds transferred within minutes of approval. I highly recommend contacting Ugo whenever you need finance. 10/10 , very happy return customer. Thanks Ugo for helping me when I needed it most."
									}
									photo={
										"https://lh3.googleusercontent.com/a/AATXAJyHoFYpecqxND6WwCEHOTQZB5OtNHP9QnZZp4fk"
									}
								/>
								<Testimonials
									name={"Wesley Thor"}
									review={
										"Today i have a requested /deal with UGO money , My situation is a bit complex so i called to discuss it with the UGO money team. the service i received was fantastic also fastest response as well.  Once you are approved the money is in your account within a few hours."
									}
									photo={
										"https://lh3.googleusercontent.com/a/AATXAJyHl4w8BeBf9LgY8gYStlFih9N35bEBU8y44kzm"
									}
								/>
								<Testimonials
									name={"mumskiiz"}
									review={
										"Ugo is amazing. The employees are very kind and patient when helping with your application. Highly recommend. Thank you for a wonderful experience"
									}
									photo={
										"https://lh3.googleusercontent.com/a-/AOh14GjgOAqZQ6cOqRAcoU-_m_J6r35aMFluf_sTcwbagA=w45-h45-p-c0x00000000-rp-mo-br100"
									}
								/>
							</>
						)}

						<label className='prev-client' htmlFor='i2'>
							<span className='client_arrow'>&#x2039;</span>
						</label>
						<label className='next-client' htmlFor='i4'>
							<span className='client_arrow'>&#x203a;</span>
						</label>
					</div>
				</div>
				<div className='slider_testimonials' id='four'>
					<div className='list-testimonials'>
						{screenWidth < 420 ? (
							<Testimonials
								name={"Ihana Tofilau"}
								review={
									"I recommend Ugo for a fast and helping hand with customers need. Thanks to Peta for assisting me over the phone with my loan. NO need to go into the branch and you get an approved within minutes. Thanks again"
								}
								photo={
									"https://lh3.googleusercontent.com/a/AATXAJyyVRtjhAZHDlEKQAol1JxXjH72uRHBM2RilcDV=w75-h75-p-rp-mo-br100"
								}
							/>
						) : (
							<>
								<Testimonials
									name={"TE STINA"}
									review={
										"Ugo are amazing!!!! I have had several loans with them now. Very reliable and fast communication, thank you so much to Peta who handled my application today."
									}
									photo={
										"https://lh3.googleusercontent.com/a-/AOh14Ghtct07PE4rA405RW2Zntefe7ZQ7cOUrnqKG7_w7g=w45-h45-p-c0x00000000-rp-mo-br100"
									}
								/>
								<Testimonials
									name={"Tarlia Davis"}
									review={
										"Ugo is a fast, friendly and easy way to get a loan when you need it, I had a unexpected funeral come up and needed money fast, ugo was there to help...I would recommend their service."
									}
									photo={
										"https://lh3.googleusercontent.com/a-/AOh14GgQ0dB1PTWTmSQwoS3JGQEkGR6QilInpxxRfY6Viw=w45-h45-p-c0x00000000-rp-mo-br100"
									}
								/>
								<Testimonials
									name={"Ihana Tofilau"}
									review={
										"I recommend Ugo for a fast and helping hand with customers need. Thanks to Peta for assisting me over the phone with my loan. NO need to go into the branch and you get an approved within minutes. Thanks again"
									}
									photo={
										"https://lh3.googleusercontent.com/a/AATXAJyyVRtjhAZHDlEKQAol1JxXjH72uRHBM2RilcDV=w75-h75-p-rp-mo-br100"
									}
								/>
							</>
						)}

						<label className='prev-client' htmlFor='i3'>
							<span className='client_arrow'>&#x2039;</span>
						</label>
						<label className='next-client' htmlFor='i5'>
							<span className='client_arrow'>&#x203a;</span>
						</label>
					</div>
				</div>
				<div className='slider_testimonials' id='five'>
					<div className='list-testimonials'>
						{screenWidth < 420 ? (
							<Testimonials
								name={"Auntaijah Johnson"}
								review={
									"The agent I spoke to was very well mannered and professional, nice and kind of I pick another loan place in the future, I would pick Ugo."
								}
								photo={
									"https://lh3.googleusercontent.com/a/AATXAJzGTRuFOO05-JuwPM_uGtpAQ5ZgHll3pvErny4D=w75-h75-p-rp-mo-br100"
								}
							/>
						) : (
							<>
								<Testimonials
									name={"Auntaijah Johnson"}
									review={
										"The agent I spoke to was very well mannered and professional, nice and kind of I pick another loan place in the future, I would pick Ugo."
									}
									photo={
										"https://lh3.googleusercontent.com/a/AATXAJzGTRuFOO05-JuwPM_uGtpAQ5ZgHll3pvErny4D=w75-h75-p-rp-mo-br100"
									}
								/>
								<Testimonials
									name={"Romina Faarii-Tuariki"}
									review={
										"I've always found Billie and co staff to be very fair and supportive with no judgement every time I've came in. Thank you Billie for your wonderful act of service."
									}
									photo={
										"https://lh3.googleusercontent.com/a/AATXAJy2CTpxZ9HHnTVP1FL7UVF8uYxeYx4BjwZjodLt=w75-h75-p-rp-mo-br100"
									}
								/>
								<Testimonials
									name={"Ali Alb"}
									review={
										"Amazing Customer service . Peta helped me and guided me through everything i highly recommend Ugo money"
									}
									photo={
										"https://lh3.googleusercontent.com/a/AATXAJxqE5zrMo7kNAZdzPcTNNsdGsflMPvHJF5vbNSM=w75-h75-p-rp-mo-br100"
									}
								/>
							</>
						)}

						<label className='prev-client' htmlFor='i4'>
							<span className='client_arrow'>&#x2039;</span>
						</label>
						<label className='next-client' htmlFor='i1'>
							<span className='client_arrow'>&#x203a;</span>
						</label>
					</div>
				</div>
				<div id='nav_slide_testimonials'>
					<label htmlFor='i1' className='dots' id='dot1'></label>
					<label htmlFor='i2' className='dots' id='dot2'></label>
					<label htmlFor='i3' className='dots' id='dot3'></label>
					<label htmlFor='i4' className='dots' id='dot4'></label>
					<label htmlFor='i5' className='dots' id='dot5'></label>
				</div>
				<div id='map' style={{ display: "none" }}></div>
			</div>
		)
	}
}
