import React, { Component } from "react"
import "./SignIn.scss"
import { Button } from "@material-ui/core"

interface IProps {
	onSignIn?: Function
	width?: string
	height?: string
	title: string
}
interface IState {}

export default class SignIn extends Component<IProps, IState> {
	render() {
		const { width, height, title } = this.props
		return (
			<div className='button-sign'>
				<Button
					onClick={() => (this.props.onSignIn ? this.props.onSignIn() : "")}
					className='btn-sign'
					style={{ width: `${width}`, height: `${height}` }}
				>
					{title}
				</Button>
			</div>
		)
	}
}
