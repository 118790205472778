import React, { Component } from "react";
import "./logoApi.scss";
// import logo from "../../accets/images/ugo.png"
import logo from "../../accets/images/logo/logo.png";
import { Link } from "react-router-dom";

interface IProps {}
interface IState {}

export default class LogoApi extends Component<IProps, IState> {
  render() {
    return (
      <>
        <div className="logo">
          <Link to="/">
            <img alt="logo" className="logo-img" src={logo} />
          </Link>
        </div>
      </>
    );
  }
}
