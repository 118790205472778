import React, { Component } from "react"
//component
import LogoApi from "./../logo/LogoApi"
import MobileMenuApp from "./../menu/MobileMenuApp"
import MenuApp from "./../menu/MenuApp"
//style
import "./Footer.scss"
import { Typography, Grid } from "@material-ui/core"

export interface IProps {
	option?: string
	userId?: string | null
}
export interface IStore {}

export default class Footer extends Component<IProps, IStore> {
	render(): JSX.Element {
		const screenWidth = window.screen.width

		// console.log("screenWidth", screenWidth)
		// console.log("screenHeight", screenHeight)
		return (
			<div>
				<Grid container item xs={12} className='footer'>
					<Grid className='footer-link'>
						<LogoApi />
						<div className='footer-link_last'>
							{screenWidth < 420 ? null : <MenuApp {...this.props} />}
						</div>
					</Grid>
					<Grid container item xs={12} className='footer-info'>
						<Typography className='footer-info_text'>
							Copyright {new Date().getFullYear()} <br />
							Ugo Ugo Pty Ltd for the Ugo Trust, ABN 90 657 969 191, Australian Credit Licence
							439604
						</Typography>
					</Grid>
				</Grid>
			</div>
		)
	}
}
