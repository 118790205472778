import axios, { AxiosResponse } from "axios";
import BaseApi from "./BaseApi";
import {
  ICalculator,
  IConfirm,
  IDashboard,
  IError,
  ILoginResponse,
  IProfileResponse,
  ISignUp,
  ISignUnSuccessful,
  IStartNewLoanRequest,
  IStartNewLoanResponce,
  ITransactions,
  ISignupSecondBody,
  ISignupSecondResponse,
  ISaveLoanParticulars,
  IVerificationEmail,
  IBanksStatement,
  ISaveBankBodyRequest,
  IBankInstructionResponse,
  ICustomerReferensNumber,
  IMyGovBankStatement,
  IAdditionalContact,
  IEmployer,
  IEmployerDetails,
  ILoanApplicationsStatus,
  IPersonalDetails,
  ICheckPersonalDetails,
  IMessagesHelperCrunch,
  IErrorMessage,
  IRestorePassword,
  ICentrelinkClientPayload,
  IHelperCrunch,
} from "../data/models";
import ErrUtils from "../validators/ErrUtils";
//import { ErrorsHandler } from "./../validators/ErrorHandler"

export default class RestApi extends BaseApi {
  //------login---------------------------------------

  // "login": "520282",
  // "password": "very_secure_TEA123"
  // "email": "une1_0_14@yopmail.com",
  // "password": "very_secure_TEA123"

  //| IError
  public static loginResponse(
    email: string,
    password: string
  ): Promise<ILoginResponse & IError> {
    const data = {
      email,
      password,
    };
    return axios
      .post(`${RestApi.HOST}/users/login`, data, RestApi.getConfig())
      .then((res) => {
        console.log("LOGIN data: ", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("Error Error Error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //--------Dashboard----------------------------------------------------------------

  public static dashboardResponse(userId: string): Promise<IDashboard> {
    //{{baseUrl}}/users/{{userId}}/dashboard
    return axios
      .get(`${RestApi.HOST}/users/${userId}/dashboard`, RestApi.getAuthConfig())
      .then((res) => {
        console.log("DASHBOARD data: ", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("dashboardResponse error", err);
        ErrUtils.buildErrMessage(err);
      });
  }
  //------profile---------------------------------------

  public static profileResponse(userId: string): Promise<IProfileResponse> {
    return axios
      .get(`${RestApi.HOST}/users/${userId}/profile`, RestApi.getAuthConfig())
      .then((res) => {
        console.log("profileResponse data: ", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //------e-signUP

  public static eSignedUpResponse(
    loanApplicationId: string
  ): Promise<IConfirm> {
    const data = {
      loanApplicationId,
    };
    return axios
      .post(
        `${RestApi.HOST}/loan-applications/e-signed`,
        data,
        RestApi.getAuthConfig()
      )
      .then((res) => {
        console.log("ESIGNUP data: ", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //------transactions---------------------------------------

  // public static transactionsResponse(
  // 	userId: string,
  // 	accountId: string
  // ): Promise<ITransactions & IError> {
  // 	return axios
  // 		.get(`${RestApi.HOST}/users/${userId}/transactions/${accountId}`, RestApi.getAuthConfig())
  // 		.then((res) => {
  // 			console.log("transactionsResponse data: ", res.data)
  // 			return res.data
  // 		})
  // 		.catch((err) => {
  // 			console.log("ERROR TransactionsResponse data:", err)
  // 			console.log("ERROR responss:", err.response.data)
  // 			ErrUtils.buildErrMessage(err)
  // 		})
  // }
  public static transactionsResponse = async (
    userId: string,
    accountId: string
  ): Promise<any> => {
    const resp = await axios.get(
      `${RestApi.HOST}/users/${userId}/transactions/${accountId}`,
      RestApi.getAuthConfig()
    );
    return resp.data;
  };

  //-------new password----------------------------------------

  public static newPasswordResponse(
    password: string,
    newPassword: string,
    userId: string
  ): Promise<any> {
    const data = {
      password,
      newPassword,
    };
    return axios
      .post(
        `${RestApi.HOST}/users/${userId}/change-password`,
        data,
        RestApi.getAuthConfig()
      )
      .then((res) => {
        console.log("NewPassword data: ", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //--------Calculator------------------------------------------------------------

  public static calculatorResponse(
    loanAdvance: number,
    numberOfPayments: number
  ): Promise<ICalculator> {
    return axios
      .get(
        `${RestApi.HOST}/calculation/${loanAdvance}/${numberOfPayments}`,
        RestApi.getAuthConfig()
      )

      .then((res) => {
        console.log("CalculatorResponse", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //--------Start new loan

  public static startNewLoanResponse(
    userId: string,
    loanAdvance: number,
    numberOfPayments: number,
    stepId: string
  ): Promise<IStartNewLoanResponce & IError> {
    const data: IStartNewLoanRequest = {
      userId,
      loanDetails: {
        loanAdvance,
        numberOfPayments,
      },
      stepId,
    };

    return axios
      .post(
        `${RestApi.HOST}/loan-applications/start-new`,
        data,
        RestApi.getAuthConfig()
      )
      .then((res) => {
        console.log("startNewLoan API data", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //----------loan applications status
  //loan-applications/{{loan_application_id}}/status
  public static loanAplicationsStatus(
    loan_application_id: string
  ): Promise<ILoanApplicationsStatus> {
    return axios
      .get(
        `${RestApi.HOST}/loan-applications/${loan_application_id}/status`,
        RestApi.getAuthConfig()
      )
      .then((res) => {
        console.log("loanAplicationsStatus", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //----------loan applications apply
  public static loanAplicationsApply(
    loanApplicationId: string,
    signatureFile: string,
    isChooseMarketing: boolean
  ): Promise<IConfirm & IError> {
    const payload = {
      loanApplicationId,
      signatureFile,
      isChooseMarketing,
    };
    return axios
      .post(
        `${RestApi.HOST}/loan-applications/apply`,
        payload,
        RestApi.getAuthConfig()
      )
      .then((res) => {
        console.log("loanAplicationsApply", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //=======================
  //--------Sign UP
  //=======================

  //-------step 1---------------------------------------------------------

  public static signUpResponse(
    clientData: ISignUp
  ): Promise<ISignUnSuccessful & IError> {
    const data = { ...clientData };
    return axios
      .post(`${RestApi.HOST}/users`, data, RestApi.getAuthConfig())
      .then((res) => {
        console.log("send client info", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //-------step 2-------------------------------------------------------

  public static signUpResponseSecond(
    isData: ISignupSecondBody,
    userId: string
  ): Promise<ISignupSecondResponse & IError> {
    const data = { ...isData };
    return axios
      .post(
        `${RestApi.HOST}/users/${userId}/signup`,
        data,
        RestApi.getAuthConfig()
      )
      .then((res) => {
        console.log("send client info", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //---------checking code received by phone-------------------------------
  public static checkEmailCode(
    fullName: string,
    email: string
  ): Promise<IVerificationEmail & IError> {
    const data: object = {
      fullName,
      email,
    };
    return axios
      .post(`${RestApi.HOST}/users/verify-email`, data, RestApi.getAuthConfig())
      .then((res) => {
        console.log("checkEmailCode", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //--------save loan particular------------------------------------------------
  public static saveLoanParticular = async (
    loanApplicationId: string,
    particularId: string,
    stepId: string
  ): Promise<(ISaveLoanParticulars & IError) | Error> => {
    const data = {
      loanApplicationId,
      particularId,
      stepId,
    };
    try {
      const resp = await axios.post(
        `${RestApi.HOST}/loan-applications/particular`,
        data,
        RestApi.getAuthConfig()
      );
      console.log("saveLoanParticular", resp.data);
      return resp.data;
    } catch (error) {
      throw new Error(error as string);
    }
    // return axios
    // 	.post(`${RestApi.HOST}/loan-applications/particular`, data, RestApi.getAuthConfig())
    // 	.then((res) => {
    // 		console.log("saveLoanParticular", res.data)
    // 		return res.data
    // 	})
    // 	.catch((err) => {
    // 		console.log("error", err)
    // 		ErrUtils.buildErrMessage(err)
    // 	})
  };

  //--------loading docs to server------------------------------------------
  public static loadingDocuments = async (
    stepId: string,
    fileName: string,
    fileData: string,
    userId: string
  ): Promise<(IConfirm & IError) | Error> => {
    const data: object = {
      stepId,
      fileName,
      fileData,
    };
    try {
      const resp = await axios.post(
        `${RestApi.HOST}/users/${userId}/upload-file`,
        data,
        RestApi.getAuthConfig()
      );
      console.log("loadingDocuments", resp.data);
      return resp.data;
    } catch (error) {
      throw new Error(error as string);
    }
    // return axios
    // 	.post(`${RestApi.HOST}/users/${userId}/upload-file`, data, RestApi.getAuthConfig())
    // 	.then((res) => {
    // 		console.log("loadingDocuments", res.data)
    // 		return res.data
    // 	})
    // 	.catch((err) => {
    // 		console.log("error", err)
    // 		ErrUtils.buildErrMessage(err)
    // 	})
  };

  //----------Bank statements------------------------------------------------

  public static listOfBanks(): Promise<IBanksStatement & IError> {
    return axios
      .get(`${RestApi.HOST}/bank-statements`, RestApi.getAuthConfig())
      .then((res) => {
        console.log("listOfBank", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  public static saveBankStatementMFA = async (
    data: ISaveBankBodyRequest
  ): Promise<(IBankInstructionResponse & IError) | Error> => {
    try {
      const resp = await axios.post(
        `${RestApi.HOST}/2/bank-statements`,
        data,
        RestApi.getAuthConfig()
      );
      console.log("saveBankStatementMFA resp", resp);
      return resp.data;
    } catch (error) {
      console.log("saveBankStatementMFA Error", error);
      // return error as Error
      throw new Error(error as string);
    }
    // return axios
    // 	.post(`${RestApi.HOST}/bank-statements`, data, RestApi.getAuthConfig())
    // 	.then((res) => {
    // 		console.log("ISaveBankBodyRequest RES:", res)
    // 		console.log("ISaveBankBodyRequest RES DATA:", res.data)
    // 		return res.data
    // 	})
    // 	.catch((err) => {
    // 		console.log("error", err)
    // 		console.log("error data", err.data)
    // 		ErrUtils.buildErrMessage(err)
    // 	})
  };

  //--------Centrelink-------------------------------------------------------------
  public static centrelinkClient = async (
    data: ICentrelinkClientPayload,
    userId: string
  ): Promise<(IConfirm & IError) | Error> => {
    try {
      const resp = await axios.post(
        `${RestApi.HOST}/2/users/${userId}/crn`,
        data,
        RestApi.getAuthConfig()
      );
      console.log("centrelinkClient", resp.data);
      return resp.data;
    } catch (error) {
      return Error(error as string);
    }
    // return axios
    // 	.post(`${RestApi.HOST}/2/users/${userId}/crn`, data, RestApi.getAuthConfig())
    // 	.then((res) => {
    // 		console.log("customerRefNumber", res.data)
    // 		return res.data
    // 	})
    // 	.catch((err) => {
    // 		console.log("error", err)
    // 		ErrUtils.buildErrMessage(err)
    // 	})
  };

  public static customerRefNumber(
    data: ICustomerReferensNumber,
    userId: string
  ): Promise<IConfirm & IError> {
    return axios
      .post(
        `${RestApi.HOST}/users/${userId}/crn`,
        data,
        RestApi.getAuthConfig()
      )
      .then((res) => {
        console.log("customerRefNumber", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //
  public static myGovBankStatement(
    userId: string,
    data: IMyGovBankStatement
  ): Promise<IConfirm & IError> {
    return axios
      .post(
        `${RestApi.HOST}/users/${userId}/mygov-bank-statments`,
        data,
        RestApi.getAuthConfig()
      )
      .then((res) => {
        console.log("myGovBankStatement", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("myGovBankStatement Error", err);
        ErrUtils.buildErrMessage(err);
      });
  }
  //additionalContact First
  public static additionalContactFirst(
    userId: string,
    data: IAdditionalContact
  ): Promise<IConfirm & IError> {
    return axios
      .post(
        `${RestApi.HOST}/users/${userId}/additional-contacts`,
        data,
        RestApi.getAuthConfig()
      )
      .then((res) => {
        console.log("additionalContact", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }
  //additionalContact Second
  public static additionalContactSecond(
    userId: string,
    data: IAdditionalContact
  ): Promise<IConfirm & IError> {
    return axios
      .post(
        `${RestApi.HOST}/users/${userId}/additional-contacts-2`,
        data,
        RestApi.getAuthConfig()
      )
      .then((res) => {
        console.log("additionalContact", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //-------Employer----------------------------------------------------------------

  public static employerDetails = async (
    userId: string,
    data: IEmployerDetails
  ): Promise<(IConfirm & IError) | Error> => {
    try {
      const resp = await axios.post(
        `${RestApi.HOST}/users/${userId}/employer-details`,
        data,
        RestApi.getAuthConfig()
      );
      console.log("employerDetails", resp.data);
      return resp.data;
    } catch (error) {
      throw new Error(error as string);
    }

    // return axios
    // 	.post(`${RestApi.HOST}/users/${userId}/employer-details`, data, RestApi.getAuthConfig())
    // 	.then((res) => {
    // 		console.log("additionalContact", res.data)
    // 		return res.data
    // 	})
    // 	.catch((err) => {
    // 		console.log("error", err)
    // 		ErrUtils.buildErrMessage(err)
    // 	})
  };

  //--------Cancel Sign Up--------------------------------------------------------

  public static cancelSignUp(userId: string): Promise<any> {
    const data = {
      userId,
    };
    return axios
      .post(`${RestApi.HOST}/users/cancel`, data, RestApi.getAuthConfig())
      .then((res) => {
        console.log("cancelSignUp", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //-------------check personal details

  public static checkPersonalDetails(
    data: IPersonalDetails
  ): Promise<ICheckPersonalDetails & IError> {
    return axios
      .post(
        `${RestApi.HOST}/users/check-personal-details`,
        data,
        RestApi.getAuthConfig()
      )
      .then((res) => {
        console.log("checkPersonalDetails", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  public static checkEmail(email: string): Promise<ICheckPersonalDetails> {
    const data = { email };
    return axios
      .post(`${RestApi.HOST}/users/check-email`, data, RestApi.getAuthConfig())
      .then((res) => {
        console.log("checkEmail", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //-----------------Restore password

  public static restorePassword = async (payload: IRestorePassword) => {
    try {
      const resp = await axios.post(
        `${RestApi.HOST}/users/forgot-password`,
        payload,
        RestApi.getAuthConfig()
      );
      console.log(`restorePassword API`, resp);
      return resp.data;
    } catch (error) {
      console.log("error", error);
      ErrUtils.buildErrMessage(error);
    }
  };

  //----------------Login to amnin

  public static loginAdmin = async (
    login: string,
    password: string
  ): Promise<AxiosResponse<any>> => {
    const data = {
      login,
      password,
    };
    const resp = await axios.post(
      `${RestApi.HOST}/admin/login`,
      data,
      RestApi.getConfig()
    );
    console.log(`loginAdmin API`, resp);
    return resp;
  };

  //-----------------Refresh bank feeds

  public static refreshBankFeeds = async (
    clientId: string,
    token: string
  ): Promise<AxiosResponse<any>> => {
    const payload = {
      clientId,
    };
    const resp = await axios.post(
      `${RestApi.HOST}/bank-statements/refresh`,
      payload,
      {
        baseURL: BaseApi.HOST,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          //Language: stores.applicationStore.getUserLanguage
        },
      }
    );
    console.log(`refreshBankFeeds API`, resp);
    return resp;
  };

  //-----------------Helper Crunch

  public static messagesHelperCrunch(userId: string): Promise<IHelperCrunch> {
    return axios
      .get(
        `${RestApi.HOST}/helpcrunch/${userId}/messages`,
        RestApi.getAuthConfig()
      )
      .then((res) => {
        console.log("messagesHelperCrunch", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  //old version
  public static createClientHelperCrunch(userId: string): Promise<any> {
    const data = {
      userId,
    };
    return axios
      .post(
        `${RestApi.HOST_HELPERCRUNCH}/customers`,
        data,
        RestApi.getHelperCrunchConfig()
      )
      .then((res) => {
        console.log("checkEmail", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  public static createChatHelperCrunch(customerId: number): Promise<any> {
    const data = {
      customer: customerId,
      assignee: null,
      department: null,
      application: null,
    };
    return axios
      .post(
        `${RestApi.HOST_HELPERCRUNCH}/chats`,
        data,
        RestApi.getHelperCrunchConfig()
      )
      .then((res) => {
        console.log("createChatHelperCrunch", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  public static getMessagesHelperCranch(
    chat_id: number
  ): Promise<IMessagesHelperCrunch & IErrorMessage> {
    return axios
      .get(
        `${RestApi.HOST_HELPERCRUNCH}/chats/${chat_id}/messages`,
        RestApi.getHelperCrunchConfig()
      )
      .then((res) => {
        console.log("getMessagesHelperCranch", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  public static createMessageHelperCranch(
    chat: number,
    text: string,
    type: string = "message"
  ): Promise<any> {
    const data = {
      chat,
      text,
      type,
    };
    return axios
      .post(
        `${RestApi.HOST_HELPERCRUNCH}/messages`,
        data,
        RestApi.getHelperCrunchConfig()
      )
      .then((res) => {
        console.log("reateMessageHelperCranch", res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("error", err);
        ErrUtils.buildErrMessage(err);
      });
  }

  // delete account
  public static deleteAccount = async (boby: any): Promise<any> => {
    try {
      const resp = await axios.post(
        `${RestApi.HOST}/users/send-confirm-delete-account-mail`,
        boby,
        RestApi.getAuthConfig()
      );
      console.log("deleteAccount", resp);
      return resp.data;
    } catch (error) {
      throw new Error(error as string);
    }
  };
}
