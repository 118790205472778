import { Box, Typography } from "@material-ui/core"
import React, { PureComponent } from "react"
import person from "./../../accets/images/icon/person.svg"
import Client1 from "./../../accets/images/Client1.png"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"

import "./Testimonials.scss"

export interface IProps {
	name?: string
	review?: string
	photo?: string
}
export interface IState {}

export default class Testimonials extends PureComponent<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {}
	}

	render(): JSX.Element {
		const name: string = this.props.name || "T'neil Watson"
		const review: string =
			this.props.review ||
			"Geoff and Bonnie helped me purchase my first car! Without them I would have been stuck because I didn't think i could afford a car loan. I was out in no time and bought my car the same day. Excellent service and very friendly staff. Excellent service and very friendly staff."
		const photo: string = this.props.photo || Client1
		return (
			<div className='testimonials-block'>
				<Box className='tm-photo'>
					<img
						alt='tm-img'
						className='tm-img'
						src={photo}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null // prevents looping
							currentTarget.src = `${person}`
						}}
					/>
				</Box>
				<Typography className='tm-name'>{name}</Typography>
				<Typography className='tm-review'>{review}</Typography>
			</div>
		)
	}
}
