import * as React from "react"
//import CircularProgress from "@mui/material/CircularProgress"
import CircularProgress from "@material-ui/core/CircularProgress"
//import Box from "@mui/material/Box"
import Box from "@material-ui/core/Box"
import "./CircularLoading.scss"

export interface IProps {
	offset?: number
}
export interface IState {}

export default class CircularLoading extends React.PureComponent<IProps, IState> {
	render(): JSX.Element {
		const screenWidth = window.screen.width
		const progressWidth = screenWidth < 420 ? 60 : 100

		return (
			<Box className='circular-loading-api' style={{ paddingTop: this.props.offset }}>
				<svg width={progressWidth} height={progressWidth}>
					<linearGradient id='loading_linear' x1='0' y1='0' x2='1' y2='1'>
						<stop offset='21.75%' stopColor='#FFA635' />
						<stop offset='40.9%' stopColor='#FF933C' />
						<stop offset='69.46%' stopColor='FF614E ' />
						<stop offset='86.15%' stopColor='FF3F5B' />
					</linearGradient>
				</svg>
				<CircularProgress thickness={7} size={progressWidth} />
			</Box>
		)
	}
}
