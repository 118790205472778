import { Component } from "react"
import "./ConfirmButton.scss"
import { Button } from "@material-ui/core"

interface IProps {
	title: string
	width: string
	passFunction?: Function
}
interface IState {
	value: string
}

export default class ConfirmButton extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)
		this.state = {
			value: "",
		}

		this.redirectToNextPage = this.redirectToNextPage.bind(this)
	}

	/**
	 * redirect to next page
	 * @private
	 * @param none
	 */
	private redirectToNextPage(): any {
		console.log("redirectToNextPage")
		if (this.props.passFunction) this.props.passFunction()
	}

	render() {
		const { width } = this.props
		return (
			<div className='button-confirm'>
				<Button className='btn-confirm' style={{ width: width }} onClick={this.redirectToNextPage}>
					{this.props.title}
				</Button>
			</div>
		)
	}
}
